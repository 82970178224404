import defaultLocations from '~/data/default_locations.json';
interface HomeState {
    current_home_search_location: object | null,
    default_well_known_locations: object[],
    is_searching: boolean,
    current_home_search_query: string,
    is_showing_search_suggestions: boolean,
    default_search_radius: number
    default_search_price_range_selection: number,
    default_search_sort_by: string
}
export const useHome = defineStore('home', {
    state: (): HomeState => {
        return {
            current_home_search_location: {
                id: "place.9607189446701850",
                type: "Feature",
                place_type: ["place"],
                relevance: 1,
                properties: { wikidata: "Q1297" },
                text: "Chicago",
                place_name: "Chicago, Illinois, United States",
                bbox: [-87.931085223, 41.625740009, -87.507792006, 42.023137],
                center: [-87.6244, 41.8756],
                geometry: { type: "Point", coordinates: [-87.6244, 41.8756] },
                context: [
                    {
                        id: "district.8754923997749290",
                        wikidata: "Q108418",
                        text: "Cook County"
                    },
                    {
                        id: "region.10854263468358810",
                        wikidata: "Q1204",
                        short_code: "US-IL",
                        text: "Illinois"
                    },
                    {
                        id: "country.19678805456372290",
                        wikidata: "Q30",
                        short_code: "us",
                        text: "United States"
                    }
                ]
            },
            default_well_known_locations: defaultLocations,
            is_searching: false,
            current_home_search_query: '',
            is_showing_search_suggestions: false,
            default_search_radius: 100,
            default_search_price_range_selection: 1,
            default_search_sort_by: "latest",
        }
    },
    actions: {
        setCurrentHomeLocation(location: object) {
            this.current_home_search_location = location;
        },
        clearCurrentHomeLocation() {
            this.current_home_search_location = null;
        },
        isSearching() {
            this.is_searching = true;
        },
        isNotSearching() {
            this.is_searching = false;
        },
        setHomeSearchQuery(homeSearchQuery: string) {
            this.current_home_search_query = homeSearchQuery;
        },
        clearHomeSearchQuery() {
            this.current_home_search_query = "";
        },
        showSearchSuggestions() {
            this.is_showing_search_suggestions = true;
        },
        hideSearchSuggestions() {
            this.is_showing_search_suggestions = false;
        }
    }
})